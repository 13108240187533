import React from "react";
import { getAffFromCookie } from "../../actions/util";

class Footer extends React.Component{
	constructor(props){
		super(props);
		this.state = {
			end_page_list:[
			{
				type:"mainSite",
				href:'help/Policy-TermsAndCondition.htm',
				_qaq : ()=>{
					global.globalGtag && global.globalGtag('Style1_QRP','Footer','Terms_footer','event');
				},
				t:'规则与条款',
			},
			{
				type:"mainSite",
				href:'help/policy-disclaimer.htm',
				_qaq : ()=>{
					global.globalGtag && global.globalGtag('Style1_QRP','Footer','Disclaimer_footer','event');
				},
				t:'免责声明',
			},
			{
				type:"mainSite",
				href:'help/policy-privacy.htm',
				_qaq : ()=>{
					global.globalGtag && global.globalGtag('Style1_QRP','Footer','Policy_footer','event');
				},
				t:'隐私权政策',
			},
			{
				type:"mainSite",
				href:'help/sportsbook-basictnc.htm',
				_qaq : ()=>{
					global.globalGtag && global.globalGtag('Style1_QRP','Footer','Rules_footer','event');
				},
				t:'一般规则'
			}
			,
			{
				type:"outSite",
				href:'/lm/?lng=CN',
				_qaq : ()=>{
					global.globalGtag && global.globalGtag('Style1_QRP','Footer','Affiliate_footer','event');
				},
				t:'联盟合作'
			}]
		}
	}
	/**
	 * 
	 * @param {String} link 
	 * @param {Number} key 
	 */
	openLink = (link , siteType) => {
		// 如果 URL 中携带 affcode 则获取出来
		let aff = getAffFromCookie();
		let { mainSiteUrl } = this.props;
		try {
			let url = mainSiteUrl;
			switch (siteType) {
				case "outSite" :
					url = mainSiteUrl + link;
					break;
				case "mainSite" :
				default :
					url = mainSiteUrl + link + `${aff ? `/?aff=${aff}` : ""}`;
					break;
			}
			window.open( url, '_blank', 'noopener,noreferrer');
		} catch (error) {
			console.log('====================================');
			console.log(error);
			console.log('====================================');
		}
	}
	
	render(){
		let { pathname } = this.props;
		return (
			<>
				<div className="footer-content-web">
					<div className={"partner"}>
						{/* 伙伴 */}
						<div className="item partner-item">
							<div className="title">合作伙伴</div>
							<div className="image-box">
							<a href="https://www.nufc.co.uk/">
									<div className="default parrtner-item-1"></div>
									<div className="footer-image-box-text">
									 	<a className="border">官方球衣赞助商</a>
										<div className="divider"></div>
									 	<a >纽卡斯尔联足球俱乐部</a>
									</div>
								</a>
								<a href="http://www.tottenhamhotspur.com/home/">
									<div className="default partner-item-0"></div>
									<div className="footer-image-box-text">
									 	<a className="border">官方球衣赞助商</a>
										 <div className="divider"></div>
									 	<a >纽卡斯尔联足球俱乐部</a>
									</div>
								</a>
							</div>
						</div>
						
						{/* 供应商 */}
						<div className="item suppliers">
							<div className="title">游戏平台</div>
							<div className="suppliers-box">
								{/* <div className="suppliers-default suppliers-item-0"></div> */}
								<div className="suppliers-default suppliers-public-style suppliers-item-1"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-2"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-3"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-4"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-5"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-6"></div>
								{/* <div className="suppliers-default suppliers-public-style suppliers-item-7"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-8"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-9"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-10"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-11"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-12"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-13"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-14"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-15"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-16"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-17"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-18"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-19"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-20"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-21"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-22"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-23"></div> */}
							</div>	
						</div>
						{/* 品牌形象大使 */}
						<div className="item brand">
							<div className="title">品牌大使</div>
							<div style={{display: 'flex'}}>
								<div className="brand-default brand-item-0"></div>
								<div className="brand-default brand-item-1"></div>
							</div>
						</div>
					</div>
					{/* 版权、 pay 、安全、责任*/}
					
					<div className="copyright-pay-safe">
						<div className="copyright">	
							<h6>运营监管: </h6>
							<a href="http://www.firstcagayan.com/"><div className="icon"></div></a>
							<a href="https://www.pagcor.ph/index.php"><div className="icon2"></div></a>
						</div>
						<div className="bank-pay">
							<h6>支付方式: </h6>
							<div className='icon icon-1'></div>
							<div className='icon icon-2'></div>
							<div className='icon icon-3'></div>
							<div className='icon icon-4'></div>
							<div className='icon icon-5'></div>
							<div className='icon icon-6'></div>
						</div>
						<div className="safe">
							<h6>信息安全: </h6>
							<div className="icon"></div>
						</div>
						<div className="responsibillity">
							<h6>博彩责任: </h6>
							<div>
								<a href="http://www.gamcare.org.uk"><div className="icon icon-1"></div></a>
								<span onClick={()=>{this.openLink('help/Policy-TermsAndCondition.htm', "mainSite")}}><div className="icon icon-2"></div></span>
							</div>
							
						</div>
					</div>
					
					{/* Motenegro */}
					{/* <div className="montenegro">
						<p>E Gambling Montenegro</p>
						<img src={'/static/images/hayhar/Montenegro-Seal.jpg'} />
						<p className={"text-gray-500"}>www.fun88.com is operated by E Gambling Montenegro d.o.o. (81000 Podgorica, Moskovska br. 65., reg. no.5-0615951) in cooperation with OG GLOBAL ACCESS LIMITED (P.O. Box 3340, Road Town, Tortola, British Virgin Islands .</p>
						<p className={"text-gray-500"}>E Gambling Montenegro d.o.o. (81000 Podgorica, Moskovska br. 65., reg. no.5-0615951) hereby certifies that under the concession (serial no. AA 001588, numeric no. 133-01/15 4A and 1ER) and approval (no. 02/01-118/4), organize and operate games of chance in Montenegro on website www.fun88.com, in accordance with the Agreement, concluded between E Gambling Montenegro d.o.o. and OG GLOBAL ACCESS LIMITED (P.O. Box 3340, Road Town, Tortola, British Virgin Islands, reg. no. 1923130) on 07 October 2021</p>
					</div> */}
					
					{/* end-page */}
					<div className="end-page">
						{/* กฎและข้อกำหนด ความรับผิดชอบ นโยบายความเป็นส่วนตัว กฎทั่วไป พันธมิตร */}
						<ul className="end-page-list">
						{this.state.end_page_list.map((val , key)=>{
								return (
									<span
										style={{color:'#FFF'}}
										key={key}
										onClick={() => {
											val._qaq();
											this.openLink(val.href, val.type);
										}}
										className="item"
									>
										{val.t}
									</span>
								)
							})}
						</ul>
						
						{/* 帮助 和 contact 联系方式*/}
						<div className="help-contact">
							{/* 帮助 */}
							<div className="help">
								<img width="25" src={'/static/images/hayhar/icon-help.png'} className="help-icon" />
								<span onClick={()=>{this.openLink('help/home.htm', "mainSite")}}>帮助与常见问题</span>
							</div>
							{/* 联系 */}
							<div className='contact-box'>
								<a className="item"></a>
								<a className="item" href="http://www.weibo.com/fun88angel?is_hot=1" target="_blank"></a>
								<a className="item" href="http://i.youku.com/Fun88Bliss" target="_blank"></a>
								<img width="25" src={'/static/images/hayhar/flag-china.png'} className="country" />
							</div>
						</div>
						
						<div className="end-page-footer-text">
							<p>FUN88 属于 Omega Sports Solutions N.V 运营</p>
							<p>FUN88 由 Curaçao Gaming Control Board 授权及监管</p>
							<p>FUN88 © 版权所有违者必究</p>
							<img 
								width="48px" 
								src={'/static/images/hayhar/certificate-GCB.png'}
								onClick={() => {
									window.open( "https://cert.gcb.cw/certificate?id=ZXlKcGRpSTZJak5LY0ZSWVNVTlVTbVo1ZG01RWJWRkdXRXRsYTJjOVBTSXNJblpoYkhWbElqb2lXR2RQY25aa1lqQTRaVkUzWjAxRWVGSnNVRmc1WnowOUlpd2liV0ZqSWpvaVpHUTRNV0kwTkRrME56UmpOekF5TldWbU16QTBNV1V4TURNelpUUmlZbUV4T1RNME9EQTRZMk15T1RGaVlUTmpOMkl6TkdZNU9EUTBOVGd6TmpFNE55SXNJblJoWnlJNklpSjk=", '_blank');
								}}
							/>
							<p>Curaçao Gaming Control Board</p>
						</div>
					</div>
				</div>
				
			</>
		)
	}
}

export default Footer;
